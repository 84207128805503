<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <v-card-text>
      <va-text-input source="login" required></va-text-input>
      <va-text-input source="password" required></va-text-input>
      <va-date-input source="shiftIni" required></va-date-input>
      <va-date-input source="shiftFin" required></va-date-input>
    </v-card-text>
    <va-save-button label="Salvar"></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      model: {
        active: false
      }
    };
  },
  created() {},
  methods: {}
};
</script>
