<template>
  <va-form :id="id" :item="newItem" disable-redirect>
    <v-card-text>
      <v-row justify="end" class="me-6 white--text">
        <v-btn color="primary" class="ma-2 white--text" @click="submit">
          Salvar
        </v-btn>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" lg="3">
          <va-text-input
            label="Titulo"
            v-model="newItem.description"
            required
          ></va-text-input>
        </v-col>

         <v-col cols="12" sm="6" lg="3">
          <va-select-input
            v-if="comboCategory.length > 0"
            label="Categoria"
            source="category_id"
            v-model="newItem.category_id"
            chip
            :choices="comboCategory"
            required
          ></va-select-input>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <va-select-input
            v-if="comboCategory.length > 0"
            label="Tipo"
            source="type_id"
            v-model="newItem.type_id"
            chip
            :choices="comboType"
            required
          ></va-select-input>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <va-date-input
            v-if="newItem != ''"
            label="Data"
            v-model="newItem.date"
            taggable
            source="date"
          ></va-date-input>
        </v-col>
        <v-col cols="12" sm="6" lg="9">
          <va-text-input
            label="Texto Resumido"
            v-model="newItem.text_resume"
            required
          ></va-text-input>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <va-boolean-input v-model="newItem.ativo"></va-boolean-input>
        </v-col>
      </v-row>
      <vue-editor v-model="newItem.text"></vue-editor>
      <div v-if="this.imagem == null && this.item != null">
        <v-col class="text-right">
          <v-file-input
            show-size
            v-on:change="onImageChange"
            label="Imagem"
            filled
            prepend-icon="mdi-camera"
          ></v-file-input>
        </v-col>
      </div>
      <va-form v-if="this.imagem != null">
        <v-col class="text-right">
          <v-btn color="red" class="me-2 white--text" @click="deleteImagem">
            Excluir Imagem
          </v-btn>
        </v-col>
        <div>
          <v-row justify="center">
            <v-col sm="6"> <v-img :src="`${imagem['url']}`"></v-img> </v-col
          ></v-row>
        </div>
      </va-form>
    </v-card-text>
    <v-snackbar v-model="snackbar" :color="color">
      {{ text }}
    </v-snackbar>
  </va-form>
</template>

<script>
import service from "@/components/detail/service_combo";
import { VueEditor } from "vue2-editor";
import moment from "moment";

export default {
  props: ["id", "title", "item"],
  components: {
    VueEditor
  },
  data() {
    return {
      model: {
        active: false
      },
      comboCategory: [],
      comboType: [],
      img: null,
      imagem: null,
      text: "",
      color: "gray",
      snackbar: false,
      newItem:
        this.item != null
          ? this.item
          : {
              description: "",
              category_id: null,
              date: "",
              ativo: true
            }
    };
  },
  created() {
    this.newItem.date = moment(new Date()).format("YYYY-MM-DD H:mm");

    if (this.item != null) {
      if (this.item.ativo == 1) {
        this.newItem.ativo = true;
      }
    }
    this.getPerson();
    this.getType();
    this.getImagem();
  },
  methods: {
    getPerson() {
      service
        .getDados({
          endpoint: "default/Category",
          slugId: "id",
          slugDesc: "description"
        })
        .then(value => {
          this.comboCategory = value;
        });
    },
    getType() {
      service
        .getDados({
          endpoint: "default/Type",
          slugId: "id",
          slugDesc: "description"
        })
        .then(value => {
          this.comboType = value;
        });
    },
    onImageChange(file) {
      let formData = new FormData();
      formData.append("image", file);
      formData.append("table", "Blog");
      formData.append("table_id", this.item.id);
      formData.append("user_id", 1);
      this.img = formData;
    },
    getImagem() {
      try {
        service
          .getImagem({
            blog_id: this.item.id
          })
          .then(resp => {
            if (resp.length > 0) {
              this.imagem = resp[0];
            }
          });
      } catch (e) {
        this.null = null;
      }
    },
    deleteImagem() {
      var msg = confirm("Deseja mesmo remover está imagem?");
      if (msg) {
        return service.deleteImagem({ id: this.imagem.id }).then(resp => {
          try {
            if (resp.status == 200) {
              this.text = resp.data.message.toString();
              this.color = "green";
              this.snackbar = true;
              this.imagem = null;
            } else {
              this.text = resp.data.message.toString();
              this.color = "red";
              this.snackbar = true;
            }
          } catch (error) {
            this.text = error.toString();
            this.color = "red";
            this.snackbar = true;
          }
        });
      }
    },
    async submit() {
      console.log(this.newItem);

      if (this.img != null) {
        return service.postImagem({ item: this.img }).then(resp => {
          try {
            if (resp.data.success == true) {
              this.$router.go(-1);
              this.getImagem();
            } else {
              this.text = resp.data.message.toString();
              this.color = "red";
              this.snackbar = true;
            }
          } catch (error) {
            this.text = error.toString();
            this.color = "red";
            this.snackbar = true;
          }
        });
      }

      if (this.newItem.id == null) {
        var user_id = localStorage.getItem("user_id");
        this.newItem.user_id = user_id;
        service
          .post({
            url: "default/Blog",
            item: this.newItem
          })
          .then(resp => {
            if (resp == true) {
              this.$router.go(-1);
            } else {
              this.text = resp.toString();
              this.color = "red";
              this.snackbar = true;
            }
          });
      } else {
        service
          .put({
            url: "default/Blog",
            item: this.newItem
          })
          .then(resp => {
            if (resp == true) {
              this.$router.go(-1);
            } else {
              this.text = resp.toString();
              this.color = "red";
              this.snackbar = true;
            }
          });
      }
    }
  }
};
</script>
