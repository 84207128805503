export default [
  {
    name: "user",
    api: "default/User",
    icon: "mdi-account",
    label: "User",
    routes: ["list"],
    translatable: true
  },
  {
    name: "blog",
    api: "default/Blog",
    icon: "mdi-post",
    label: "Blog"
  },
  {
    name: "category",
    api: "default/Category",
    icon: "mdi-shape",
    label: "Categoria",
    routes: ["list"],
    translatable: true
  },
   {
    name: "type",
    api: "default/Type",
    icon: "mdi-view-list",
    label: "Tipos",
    routes: ["list"],
    translatable: true
  }
];
