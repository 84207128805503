<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list ref="list" :filters="filters">
      <va-data-table :fields="fields" disable-clone disable-show disable-delete>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: ["id", "description"],
      fields: [
        { source: "id", sortable: true },
        { source: "description", sortable: true },
        { source: "date", type: "date", sortable: true },
         { source: "type_id", sortable: true },
        {
          source: "ativo",
          type: "boolean",
          label: "Ativo"
        }
      ]
    };
  },
};
</script>
