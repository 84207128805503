<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <v-card-text>
      <va-text-input source="description" required></va-text-input>
    </v-card-text>
    <va-save-button label="Salvar"></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      model: {
        active: false
      }
    };
  }
};
</script>
