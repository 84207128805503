<template>
  <va-create-layout>
    <blog-form :title="title" :item="item"></blog-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
