import service from "@/services/BaseService";

export default {
  // Busca dados na api e retorna lista para a combo
  async getDados({ endpoint, slugId, slugDesc }) {
    let items = [];
    try {
      var getCombo = new service(endpoint);
      await getCombo.find().then(value => {
        for (let i = 0; i < value.data.data.length; i++) {
          items.push({
            value: value.data.data[i][slugId],
            text: value.data.data[i][slugDesc]
          });
        }
      });
      return items;
    } catch (error) {
      return [];
    }
  },
  // Busca dados na api e retorna lista para a combo
  async getPointsMap({ endpoint, slugId, slugDesc }) {
    let items = [];
    try {
      var getCombo = new service(endpoint);
      await getCombo.find().then(value => {
        for (let i = 0; i < value.data.data.data.length; i++) {
          items.push({
            value: value.data.data.data[i][slugId],
            text: value.data.data.data[i][slugDesc]
          });
        }
      });
      return items;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
  getImagem({ blog_id }) {
    let item = [];
    var getValues = new service(`ImageMap/Blog/${blog_id}/image`);
    return getValues.find().then(value => {
      value.data.data.forEach(element => {
        element.url = `https://server.admsistema.com.br/api/v1/USERIMAGE/${element.id}/image`;
        item.push(element);
      });
      return item;
    });
  },
  postImagem({ item }) {
    var postValue = new service(`USERIMAGE`);
    return postValue
      .createFormData(item)
      .then(value => {
        return value;
      })
      .catch(e => {
        return e;
      });
  },
  deleteImagem({ id }) {
    var postValue = new service(`USERIMAGE`);
    return postValue
      .delete(id)
      .then(value => {
        return value;
      })
      .catch(e => {
        return e;
      });
  },
  post({ url, item }) {
    var postValue = new service(url);
    return postValue
      .create(item)
      .then(() => {
        return true;
      })
      .catch(e => {
        return e;
      });
  },
  put({ url, item }) {
    var putValue = new service(`${url}`);
    return putValue
      .update(item.id, item)
      .then(() => {
        return true;
      })
      .catch(e => {
        return e;
      });
  }
};
