import i18n from "@/i18n";

/**
 * Date format
 */
["pt"].forEach((locale) => {
  i18n.setDateTimeFormat(locale, {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    },
  });
});

i18n.setNumberFormat("pt", {
  currency: {
    style: "currency",
    currency: "BRL",
  },
});
